import React, { useRef, useState } from "react";
import axios from "axios";
import { ApiBaseUrl, pdfUploadApi } from "../../utils/Constants";

const PdfFileUpload = ({
  projectId,
  setShowOptionList,
  onPdfUploadSuccess,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = (event) => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }

    // const file = event.target.files[0];
    const formData = new FormData();
    formData.append("projectId", projectId);
    formData.append("pdf", selectedFile);
    setLoading(true);
    axios
      .post(ApiBaseUrl + pdfUploadApi, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log(response);
        // setShowOptionList(false);
        alert(
          "File uploaded successfully!, Now you can start start the question from the pdf"
        );

        if (response.data && onPdfUploadSuccess) {
          onPdfUploadSuccess(response.data);
        }
        setSelectedFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // const handleclose = () => {
  //   setShowOptionList(false);
  // };
  return (
    <div className="upload-pdf">
      {/* <button onClick={handleclose} className="upload-close-btn">Close</button> */}
      <input type="file" onChange={handleFileChange}  ref={fileInputRef} />
      <button onClick={handleFileUpload} disabled={!selectedFile || loading}>
        {loading ? "Uploading..." : "Upload"}
      </button>
    </div>
  );
};

export default PdfFileUpload;
