import React, { useEffect, useState } from "react";
import notification from "../../assets/img/notification.svg";
import user_icon from "../../assets/img/user_icon.svg";
import down_icon from "../../assets/img/down_icon.svg";
import ai_img from "../../assets/img/ai_img.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentList } from "../../redux/GetDocumentListSlice";
import { ApiBaseUrl } from "../../utils/Constants";
import { format, parseISO } from "date-fns";
import { getProjectList } from "../../redux/GetProjectListSlice";
import Header from "../Header/Header";

const Documents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [documentsList, setDocumentsList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const documentResponse = useSelector(
    (state) => state.getDocumentListReducer.data
  );
  const projectResponse = useSelector(
    (state) => state.getProjectListReducer.data
  );

  useEffect(() => {
    dispatch(getDocumentList());
    dispatch(getProjectList());
  }, []);

  useEffect(() => {
    console.log("Document Response ===> ", documentResponse);
    if (documentResponse != null && documentResponse.status == 1) {
      setDocumentsList(documentResponse.data);
    }
  }, [documentResponse]);

  useEffect(() => {
    console.log("Project Response ===> ", projectResponse);
    if (projectResponse != null && projectResponse.status == 1) {
      setProjectList(projectResponse.data);
    }
  }, [projectResponse]);
  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, "MMMM d, yyyy h:mm:ss a");
  };
  const handleProjectChange = (e) => {
    setSelectedProject(e.target.value);
  };

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredDocuments = documentsList.filter((doc) => {
    const project = projectList.find((proj) => proj._id === doc.projectId);
    const projectName = project ? project.projectName.toLowerCase() : "";

    return (
      (selectedProject === "" || doc.projectId === selectedProject) &&
      (doc.chatName.toLowerCase().includes(searchQuery) ||
        projectName.includes(searchQuery))
    );
  });

  const handleSearchClick = (documentId, projectId) => {
    navigate("/YourAi", {
      state: {
        documentId,
        projectId,
      },
    });
  }; 

  return (
    <div className="sidebar_mar">
      <Header />

      <div className="document_content">
        <h2>Documents</h2>

        <div className="two_input_flex">
          <div className="label_input_flex">
            <label>Project</label>
            <select
              className="project-select"
              value={selectedProject}
              onChange={handleProjectChange}
            >
              <option value="">All Projects</option>
              {projectList.map((row, index) => (
                <option key={row._id} value={row._id}>
                  {row.projectName}
                </option>
              ))}
            </select>
          </div>

          <div className="label_input_flex">
            <label>Search query</label>
            <input
              type="text"
              placeholder="Search by document or project name..."
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
          </div>
        </div>

        <div className="table_style">
          <table className="data-table">
            <thead>
              <tr>
                <th className="text_center">Relevance</th>
                <th>Name</th>
                <th>Created At</th>
                <th className="text_center">Source</th>
                <th>Project</th>
                <th>Path</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredDocuments.length > 0 ? (
                filteredDocuments.map((row, index) => {
                  const project = projectList.find(
                    (proj) => proj._id === row.projectId
                  );
                  return (
                    <tr key={index}>
                      <td className="text_center">
                        <b>-</b>
                      </td>
                      <td title={row.chatName}>
                        {row.chatName.length > 30
                          ? row.chatName.substring(0, 30) + "..."
                          : row.chatName}
                      </td>

                      <td className="color_gray">
                        {formatDate(row.createdAt)}
                      </td>
                      <td className="text_center">
                        <img src={ai_img} alt="ai_img" />
                      </td>
                      <td>{project?.projectName || "Unknown"}</td>
                      <td className="color_gray">
                        <a
                          href={ApiBaseUrl + "/pdf/" + row.pdf}
                          target="_blank"
                        >
                          View Pdf
                        </a>
                      </td>
                      <td>
                        <button
                          onClick={() =>
                            handleSearchClick(row._id, row.projectId)
                          }
                        >
                          Search
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7" className="text_center">
                    No documents found for this project
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Documents;
